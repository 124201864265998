import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { useSessionContext } from '../contexts/session'

const PRETTO_PROXY_URL = process.env.REACT_APP_PRETTO_PROXY_URL ?? 'missing REACT_APP_PRETTO_PROXY_URL'
const PRETTO_COOKIE_DOMAIN = process.env.REACT_APP_PRETTO_COOKIE_DOMAIN ?? 'missing REACT_APP_PRETTO_COOKIE_DOMAIN'

const OLEEN_PROXY_URL = process.env.REACT_APP_OLEEN_PROXY_URL ?? 'missing REACT_APP_OLEEN_PROXY_URL'
const OLEEN_COOKIE_DOMAIN = process.env.REACT_APP_OLEEN_COOKIE_DOMAIN ?? 'missing REACT_APP_OLEEN_COOKIE_DOMAIN'

const PROXY_PATH = process.env.REACT_APP_PROXY_PATH ?? 'missing REACT_APP_PROXY_PATH'

const setCookie = ({ domain, token }: { domain: string, token: string }) => {
  const cookie = `access_token=${token};domain=${domain};path=/;SameSite=None; Secure`
  console.log(`Setting cookie: ${cookie}`)
  document.cookie = cookie
}

const navigateTo = ({ host, path, params }: { host: string, path: string, params: URLSearchParams }) => {
  const url = `${host}${path}?${params.toString()}`
  console.log(`Redirecting to ${url}`)
  window.location.replace(url)
}

export const Content = () => {
  const { token } = useSessionContext()


  useEffect(() => {
    if (token) {
      console.log(window.location.href)
      console.log(window.location.search)

      const params = new URLSearchParams(window.location.search);
      const mediaURI = params.get('media');

      const decoded: Record<string, string> = jwtDecode(token)

      console.log({ sub: decoded.sub})
      console.log({ host: window.location.host })
      console.log({ prettoCookieDomain: PRETTO_COOKIE_DOMAIN })
      console.log({ oleenCookieDomain: OLEEN_COOKIE_DOMAIN })

      let path = window.location.pathname.startsWith(PROXY_PATH)
        ? window.location.pathname
        : `${PROXY_PATH}${window.location.pathname}`

      if (mediaURI) {
        console.log({ mediaURI })
        path = `${PROXY_PATH}/media${mediaURI}`
        params.delete('media')
        params.append('fckme', 'true')
      }

      console.log({ path })
      console.log({ params })

      if (window.location.host.includes(PRETTO_COOKIE_DOMAIN)) {
          setCookie({ token, domain: PRETTO_COOKIE_DOMAIN })
          navigateTo({host: PRETTO_PROXY_URL, path, params})
          return
      }

      if (window.location.host.includes(OLEEN_COOKIE_DOMAIN)) {
        setCookie({ token, domain: OLEEN_COOKIE_DOMAIN })
        navigateTo({ host: OLEEN_PROXY_URL, path, params })
        return
      }
    }
  }, [token])

  return <></>
}
